import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { FareModel } from '@fleet/model';

@Component({
  selector: 'fleet-job-card-fare-detail',
  templateUrl: './job-card-fare-detail.component.html',
  styleUrls: ['./job-card-fare-detail.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class JobCardFareDetailComponent implements OnInit {
  _fare: FareModel;
  @Input()
  set fare(value: FareModel) {
    // value = {
    //   fareId: 'a20c37b24',
    //   jobId: 'a20c37b24',
    //   elements: [
    //     {
    //       group: 'TARIFF',
    //       type: 'JOURNEY',
    //       title: 'Base Fare',
    //       description: 'Base fare for the trip',
    //       distance: '1000.0000',
    //       distanceUnit: 'M',
    //       duration: '30.0000',
    //       durationUnit: 'MIN',
    //       amount: '50.00',
    //       amountTaxExclusive: '50.00',
    //       totalTax: '0.00',
    //       items: [
    //         {
    //           type: 'BASE',
    //           amount: '20.50',
    //           amountTaxExclusive: '20.50',
    //           totalTax: '0.00',
    //         },
    //         {
    //           type: 'ADJUSTMENT',
    //           distance: '1000.0000',
    //           distanceUnit: 'M',
    //           duration: '30.0000',
    //           durationUnit: 'MIN',
    //           amount: '29.50',
    //           amountTaxExclusive: '29.50',
    //           totalTax: '0.00',
    //         },
    //       ],
    //     },
    //     {
    //       group: 'EXTRAS',
    //       type: 'CLEANING',
    //       title: 'Cleaning fee',
    //       description: 'Vehicle cleaning fee',
    //       amount: '3.00',
    //       amountTaxExclusive: '3.00',
    //       totalTax: '0.00',
    //     },
    //     {
    //       group: 'TOLL',
    //       type: 'TOLL',
    //       title: 'Total Tolls',
    //       description: 'Net toll amount for the route',
    //       amount: '4.00',
    //       amountTaxExclusive: '4.00',
    //       totalTax: '0.00',
    //       items: [
    //         {
    //           type: 'BASE',
    //           amount: '7.00',
    //           amountTaxExclusive: '7.00',
    //           totalTax: '0.00',
    //         },
    //         {
    //           type: 'ADJUSTMENT',
    //           amount: '-3.00',
    //           amountTaxExclusive: '-3.00',
    //           totalTax: '0.00',
    //         },
    //       ],
    //     },
    //     {
    //       group: 'FEE',
    //       type: 'CONGESTION_FEE',
    //       title: 'Congestion fee',
    //       description: 'Congestion fee',
    //       amount: '6.00',
    //       amountTaxExclusive: '6.00',
    //       totalTax: '0.00',
    //       items: [
    //         {
    //           type: 'BASE',
    //           amount: '2.50',
    //           amountTaxExclusive: '2.50',
    //           totalTax: '0.00',
    //         },
    //         {
    //           type: 'ADJUSTMENT',
    //           amount: '3.50',
    //           amountTaxExclusive: '3.50',
    //           totalTax: '0.00',
    //         },
    //       ] as any,
    //     },
    //   ],
    //   totalTariff: '50.00',
    //   totalExtras: '3.00',
    //   totalTolls: '0.00',
    //   totalLevy: '0.00',
    //   totalFees: '6.00',
    //   totalFare: '56.00',
    //   totalSubsidy: '0.00',
    //   totalSale: '56.00',
    //   totalTax: '0.00',
    //   totalDistance: '1000.00',
    //   distanceUnit: 'M',
    //   totalDuration: '30.00',
    //   durationUnit: 'MIN',
    //   currencyCode: 'USD',
    //   pricingScheme: {
    //     pricingSchemeReference: 'a09abe9db-a17179149-a05ec2c48',
    //   },
    // } as any;

    this._fare = value;
    if (this._fare && this._fare.elements) {
      this._fare.elements.forEach((element: any) => {
        if (!('expandGrouping' in element)) {
          element.expandGrouping = false;
        }

        if (!('groupingReference' in element)) {
          element.groupingReference = `${element.type}_${Math.random()
            .toString(36)
            .substr(2, 9)}`;
        }
        element.expandGrouping = element.items && element.items.length > 1;

        if ('distance' in element) {
          element.distance = parseFloat(element.distance).toFixed(2);
        }

        if (element.items) {
          element.items.forEach((item: any, index: number) => {
            if (!('groupingReference' in item)) {
              item.groupingReference = `${element.type}_${index}`;
            }

            if ('distance' in item) {
              item.distance = parseFloat(item.distance).toFixed(2);
            }
          });
        }
      });
    }
  }

  get fare(): FareModel {
    return this._fare;
  }

  expandedGroup: any;

  orderedFareGroupTypes = [
    'Tariff',
    'Extras',
    'Toll',
    'Levy',
    'Fee',
    'Subsidy',
  ];
  constructor() {}

  ngOnInit(): void {}
}
