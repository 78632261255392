import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { paths } from '@fleet/environment';
import { encodeParams, handleApiError } from '@fleet/utilities';

@Injectable({
  providedIn: 'root',
})
export class AppleApiService {
  private host: string;

  constructor(private http: HttpClient, @Inject('env') env: any) {
    this.host = env.host + paths.appleIntegration;
  }

  searchAppleKeys(params: any): Observable<any> {
    return this.http
      .get(`${this.host}/key`, {
        params: encodeParams(params),
      })
      .pipe(catchError(handleApiError));
  }

  createAppleKey(payload: any): Observable<any> {
    return this.http
      .post(`${this.host}/key`, payload)
      .pipe(catchError(handleApiError));
  }

  deleteAppleKey(intergrationId: string): Observable<any> {
    return this.http
      .delete(`${this.host}/key/${intergrationId}`)
      .pipe(catchError(handleApiError));
  }
}
